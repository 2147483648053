<template>
  <div class="backgroundLoin">
    <el-card class="box-card clearfix">
      <div class="lf boxLeft">
        <img src="../../assets/login/icon_experiment_background.png" />
      </div>
      <div
        class="rf boxRight"
        :style="weChatFlag ? 'padding-top:60px;padding-bottom:10px' : ''"
      >
        <img
          @click="weChatFlagBtn"
          class="WeChatImg"
          src="../../assets/login/icon_experiment_wechat.png"
        />
        <div
          class="boxRightTit"
          :style="weChatFlag ? 'margin-bottom:20px' : ''"
        >
          欢迎登录<span> — 管理后台</span>
        </div>
        <template v-if="weChatFlag">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 280px;
            "
            v-loading="weChatImgLoading"
          >
            <div
              v-if="imgOverdue"
              style="
                width: 240px;
                height: 240px;
                position: relative;
                background: #6865659f;
                color: #fff;
                border-radius: 50px;
              "
              @click="getLoginStatusFunc"
            >
              <img
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  opacity: 0.2;
                "
                onerror="this.onerror='';this.src='img/userphoto.png'"
                :src="weChatImg"
              />
              <div
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-wrap: wrap;
                  cursor: pointer;
                  z-index: 90;
                "
              >
                <div style="text-align: center">
                  <i
                    style="display: block; font-size: 40px; margin-bottom: 10px"
                    class="el-icon-refresh-right"
                  ></i>
                  二维码失效，点击刷新
                </div>
              </div>
            </div>
            <img
              v-else
              style="width: 240px; height: 240px"
              onerror="this.onerror='';this.src='img/userphoto.png'"
              :src="weChatImg"
            />
          </div>
          <div style="text-align: center">{{ weChatMsg }}</div></template
        >
        <template v-else>
          <el-form ref="formLion" :rules="rules" :model="formLion">
            <el-form-item label="登录方式" v-if="0">
              <el-radio-group v-model="formLion.loginType">
                <el-radio :disabled="disabled" :label="1">账号</el-radio>
                <el-radio :disabled="disabled" :label="2">手机号</el-radio>
                <el-radio :disabled="disabled" :label="3">邮箱</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="account">
              <el-input
                v-model.trim="formLion.account"
                :placeholder="accountText"
                :disabled="disabled"
                maxlength="15"
              >
                <template slot="prepend"
                  ><i class="el-icon-user-solid"></i></template
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="formLion.password"
                placeholder="请输入密码"
                show-password
                :disabled="disabled"
                maxlength="15"
                onkeyup="this.value=this.value.replace(/[^\w_]/g,'')"
              >
                <template slot="prepend"><i class="el-icon-lock"></i></template>
              </el-input>
            </el-form-item>
            <el-form-item style="margin: 40px 0 20px">
              <el-button
                v-throttle
                type="primary"
                :loading="loadingBtn"
                @click="onLogin('formLion')"
                >登录 <i class="el-icon-right"></i
              ></el-button>
            </el-form-item>
          </el-form>
        </template>

        <div class="jump">
          <router-link to="./businessLogin">
            <el-link type="primary">去登录业务平台</el-link>
          </router-link>
        </div>
      </div>
    </el-card>
    <div class="bottom_tit">{{ versionNum }}</div>
  </div>
</template>

<script>
import { debounce } from '@/utils/util.js'
import { login, getWebLogin, getLoginStatus, getVersion } from '@/api/auth.js'
import {
  setToken,
  setName,
  setStore,
  getStore,
  removeStore,
} from '@/utils/token.js'
export default {
  name: 'manageLogin',
  data() {
    return {
      //版本号
      versionNum: 'lims.v0.0.1_beta',
      formLion: {
        account: '',
        // 登录类型,1为账号登录，2为手机号登录，3为邮箱登录
        loginType: 1,
        password: '',
        userType: 0,
        loginTerminalType: 11,
      },
      accountText: '请输入用户名',
      loadingBtn: false,
      disabled: false,
      // 验证
      rules: {
        account: [
          { required: true, message: '账号不能为空', trigger: 'blur' },
          {
            min: 4,
            max: 15,
            message: '长度在 4 到 15 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          {
            min: 4,
            max: 15,
            message: '长度在 4 到 15 个字符',
            trigger: 'blur',
          },
        ],
      },
      // 扫码登录
      weChatFlag: false,
      weChatImg: '',
      // 扫码提示
      weChatMsg: '请扫码登录',
      weChatCode: '',
      timeout: null,
      imgOverdue: false,
      onerror: '',
    }
  },
  watch: {
    'formLion.loginType': function (newVal, oldVal) {
      // this.formLion.userType = newVal;
      switch (newVal) {
        case 2:
          this.accountText = '请输入手机号'
          break
        case 3:
          this.accountText = '请输入邮箱'
          break
        default:
          this.accountText = '请输入账号'
      }
    },
    weChatFlag(val) {
      if (!val) {
        clearInterval(this.timeout)
        this.timeout = null
      }
    },
  },
  mounted() {
    this.getVersionFunc()
    clearInterval(this.timeout)
    this.timeout = null
    // 监听回车事件
    let _this = this
    document.onkeydown = function (e) {
      let ev = document.all ? window.event : e
      if (ev.keyCode === 13) {
        _this.onLogin('formLion')
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timeout)
    this.timeout = null
  },
  methods: {
    onLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.disabled = this.loadingBtn = true
          // 在此发送请求
          login(this.formLion)
            .then((res) => {
              this.disabled = this.loadingBtn = false
              console.log(res)
              if (res.success) {
                // 存储Token vuex 或者 浏览器上储存
                setToken(res.data.token)
                setStore('userId', res.data.userId)
                setName(this.formLion.account)
                this.$message.success('登录成功，正在转跳首页···')
                // 成功后跳转路由
                setTimeout(() => {
                  this.$router.replace('/homepage')
                }, 500)
              } else {
                this.$message.warning(res.msg)
              }
            })
            .catch((err) => {
              this.disabled = this.loadingBtn = false
              this.$message.warning('登录失败')
            })
        } else {
          return false
        }
      })
    },
    weChatFlagBtn: debounce(async function () {
      clearInterval(this.timeout)
      this.timeout = null
      this.weChatFlag = !this.weChatFlag
      if (this.weChatFlag) {
        this.weChatImgLoading = true
        await this.getLoginStatusFunc()
      }
    }),
    // 用户获取小程序码登陆状态 轮询
    getLoginStatusFunc: debounce(async function () {
      await getWebLogin()
        .then((res) => {
          this.weChatImgLoading = false
          this.imgOverdue = false
          if (res.success) {
            this.weChatImg = res.data.imgData
            this.weChatCode = res.data.code.slice(2)
            // 轮询
            this.timeout = setInterval(() => this.getWebLoginFunc(), 1000)
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    }),
    // 用户获取网页登录用的小程序码
    async getWebLoginFunc() {
      let fd = new FormData()
      fd.append('code', this.weChatCode)
      await getLoginStatus(fd)
        .then((res) => {
          if (res.success) {
            clearInterval(this.timeout)
            // 存储Token vuex 或者 浏览器上储存
            setToken(res.data.token)
            setStore('userId', res.data.userId)
            setName(this.formLion.account)
            this.$message.success('登录成功，正在转跳首页···')
            // 成功后跳转路由
            setTimeout(() => {
              this.$router.replace('/homepage')
            }, 300)
          } else {
            this.weChatMsg = res.msg
          }
          if (res.code == 907) {
            this.imgOverdue = true
            clearInterval(this.timeout)
            this.timeout = null
          }
        })
        .catch((err) => {})
    },
    getVersionFunc() {
      getVersion()
        .then((res) => {
          this.versionNum = res.data
        })
        .catch((err) => {})
    },
  },
}
</script>
<style scoped>
.backgroundLoin {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: url(../../assets/login/img_background.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .box-card {
  width: 900px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 1px #ffffffa8;
}
::v-deep .el-card__body {
  padding: 0 !important;
}
.el-card__body > div {
  /* width: 50%; */
  box-sizing: border-box;
}
.el-card__body .boxLeft {
  width: 42%;
  background: #daecfd;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.boxLeft img {
  width: 100%;
}
.el-card__body .boxRight {
  width: 58%;
  padding: 58px;
  padding-top: 120px;
  box-sizing: border-box;
  position: relative;
}
.boxRight .WeChatImg {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  overflow: hidden;
  border-bottom-left-radius: 100px;
  display: block;
}
.boxRightTit {
  /* text-align: center; */
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 38px;
  color: #002e6e;
  /* letter-spacing: 6px; */
}
.boxRightTit span {
  font-size: 24px;
  font-weight: normal;
  color: #002e6e;
}
.el-button--primary {
  width: 100%;
}

.bottom_tit {
  position: absolute;
  bottom: 10px;
  color: #002e6e;
  font-size: 18px;
}
.jump {
  width: 100%;
  text-align: center;
}
img[src=''],
img:not([src]) {
  opacity: 0;
}
</style>
